<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        md="4"
        offset="4"
      >
        <!-- <analytics-congratulation /> -->
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="3"
        v-if="iCan('surveys', 'write')"
      >
        <a href="/en/surveys/edit/new">
          <statistic-card-vertical
            color="primary"
            icon="ClipboardIcon"
            :statistic="i18nT(`Survey`)"
            :statistic-title="i18nT(`Create new survey`)"
          />
        </a>
      </b-col>
      <b-col
        md="3"
        v-if="iCan('suggestions', 'write')"
      >
        <a href="/en/suggestions/edit/new">
          <statistic-card-vertical
            color="primary"
            icon="InboxIcon"
            :statistic="i18nT(`Suggestion`)"
            :statistic-title="i18nT(`Create new suggestion`)"
          />
        </a>
      </b-col>
      <b-col
        md="3"
        v-if="iCan('questions', 'write')"
      >
        <a href="/en/questions/edit/new">
          <statistic-card-vertical
            color="primary"
            icon="UserCheckIcon"
            :statistic="i18nT(`Evaluation`)"
            :statistic-title="i18nT(`Create new evaluation`)"
          />
        </a>
      </b-col>
      <b-col
        md="3"
      >
          <b-link :to="{
            name: `inbox-new`,
            query: { newMsg: 1}
          }">
              <statistic-card-vertical
                color="primary"
                icon="MailIcon"
                :statistic="i18nT(`Message`)"
                :statistic-title="i18nT(`Send new message`)"
              />
          </b-link>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
          v-if="iCan('surveys', 'read')"
          lg="4">
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title class="ml-25">
              {{surveys.count}} {{i18nT(`Surveys`)}}
            </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <b-media no-body v-for="survey in surveys.items" :key="survey.Id" class="media pb-50 pt-50 border-bottom">
              <b-media-body class="my-auto">
                <h6 class="mb-0 text-primary">
                  {{ survey.Label }}
                </h6>
                <p class="mb-0" v-html="survey.Description"></p>
              </b-media-body>
            </b-media>
            <b-media style="text-align: center;" v-if="surveys.count === 0">
              <span class="b-avatar badge-light-secondary rounded mb-2 mt-4" style="width: 70px; height: 70px;">
              <feather-icon
                icon="ClipboardIcon"
                size="36"
              />
              </span>
              <h5>{{i18nT(`Nothing new`)}}</h5>
              <p class="text-muted"> {{i18nT(`There are no surveys.`)}}</p>
            </b-media>
            <!--/ timeline -->
          </b-card-body>
          <a href="/en/surveys" style="padding: 10px;">
            <b-button variant="outline-secondary" style="width: 100%">
              {{i18nT(`See all`)}}
            </b-button>
          </a>
        </b-card>
      </b-col>
      <b-col lg="4"
             v-if="iCan('suggestions', 'read')"
      >
        <b-card
          no-body
        >
          <b-card-header>
            <b-card-title class="ml-25">
              {{suggestions.count}} {{i18nT(`Suggestions`)}}
            </b-card-title>
          </b-card-header>

          <!-- timeline -->
          <b-card-body>
            <b-media no-body v-for="suggestion in suggestions.items" :key="suggestion.Id" class="media pb-50 pt-50 border-bottom">
              <b-media-body class="my-auto">
                <h6 class="mb-0 text-primary">
                  {{ suggestion.Label }}
                </h6>
                <p class="mb-0" v-html="suggestion.Description"></p>
              </b-media-body>
            </b-media>
            <b-media style="text-align: center;" v-if="surveys.count === 0">
              <span class="b-avatar badge-light-secondary rounded mb-2 mt-4" style="width: 70px; height: 70px;">
              <feather-icon
                icon="InboxIcon"
                size="36"
              />
              </span>
              <h5>{{i18nT(`Nothing new`)}}</h5>
              <p class="text-muted"> {{i18nT(`There are no suggestions.`)}}</p>
            </b-media>
            <!--/ timeline -->
          </b-card-body>
          <a href="/en/suggestions" style="padding: 10px;">
           <b-button variant="outline-secondary" style="width: 100%">
              {{i18nT(`See all`)}}
            </b-button>
          </a>
        </b-card>
      </b-col>
      <b-col lg="4">
        <analytics-messages :data="messages" manageLink="/re/inbox"/>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BCardHeader, BCardTitle, BMedia, BMediaBody, BMediaAside, BAvatar, BButton, BBadge, BLink  } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import AnalyticsCongratulation from './analytics/AnalyticsCongratulation.vue'
import AnalyticsMessages from './analytics/AnalyticsMessages.vue'
import StatisticCardVertical from '@core/components/statistics-cards/StaticCardFA'
import useAuth from '@/auth/useAuth'
import _ from 'lodash';


export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsMessages,
    StatisticCardVertical,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaBody,
    BButton,
    BBadge,
    BLink
  },
  data() {
    return {
      data: {},
      surveys: {
         items: [],
         count: 0
      },
      suggestions: {
        items: [],
        count: 0
      },
      messages: {
        messages: [],
        count: 0
      }
    }
  },
  created() {
    const currentSoftware = useAuth.getCurrentSoftware()

    this.$http.get(
      `main/enDashboard`
    ).then(({data}) => {
      this.surveys = {
        items: data.data.surveys,
        count: data.data.surveys_count
      };
      this.suggestions = {
        items: data.data.suggestions,
        count: data.data.suggestions_count
      }
      this.messages = {
        messages: data.data.messages,
        count: data.data.messages_count
      }
    });

    // data
    // this.$http.get(
    //   `surveys`
    // ).then(({data}) => {
    //   this.surveys = _.values(data.data)
    // })

    // this.$http.get(
    //   `suggestions`
    // ).then(({data}) => {
    //   this.suggestions = _.values(data.data)
    // })

    // this.$http.get(
    //   `messages`
    // ).then(({data}) => {
    //   this.messages = _.values(data.data)
    // })
  },
  methods: {
    kFormatter,
  },
}
</script>
